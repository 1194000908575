.custom-ten-position-layout {
    height: 100%;
    overflow-y: auto;
    display: grid;
    grid-template-areas:
        "pos1 pos2"
        "pos1 subgrid";
    grid-template-columns: 2fr 3fr;
    /* Left column is wider */
    grid-template-rows: auto 1fr;
    /* Rows for Position 2 and the subgrid */
}

.custom-ten-position-layout {
    .position-1 {
        grid-area: pos1;
        padding: 2px;
        font-size: 14px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        box-sizing: border-box;
    }

    .position-2 {
        grid-area: pos2;
        padding: 2px;
        font-size: 14px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        box-sizing: border-box;
    }

    .subgrid {
        grid-area: subgrid;
        display: grid;
        grid-template-areas:
            "pos3 pos4"
            "pos5 pos6"
            "pos7 pos8"
            "pos9 pos10";
        grid-template-columns: 1fr 1fr;
        /* Two equal-width columns */
    }

    .position-3,
    .position-4,
    .position-5,
    .position-6,
    .position-7,
    .position-8,
    .position-9,
    .position-10 {
        padding: 2px;
        font-size: 14px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        box-sizing: border-box;
    }
}