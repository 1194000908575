.sidebar-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.e-normal,
.e-normal:hover,
.e-noraml:active {
    background: transparent;
    /* box-shadow: none; */
}

.e-sidebar {
    position: absolute;
    padding: 15px;
}
/*.default-sidebar {*/
/*    position: absolute;*/
/*    padding: 15px;*/
/*    display: block;*/
/*}*/

.e-close{
    display: none;
}

.drawer-wrapper {
    height: 100%;
    cursor: auto;
}

#close {
    margin-top: 5px;
    height: 32px;
    color: black;
    padding: 10px;
    cursor: pointer;
}
#close:hover {
    font-weight: bold;
    background-color: rgba(224, 224, 224, 0.53);
}
.sidebar-settings-title{
    margin-top: 10px;
    font-size: 18px;
    font-weight: normal;
}

.sidebar-content {
    font-size: 15px;
}